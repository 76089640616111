export type Schedule = {
  id: number;
  day: number;
  hour: number;
};

const getSchedules = () => {
  const res: Schedule[] = [];
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 24; j++) {
      res.push({id: res.length, day: i, hour: j});
    }
  }
  return res;
};

export const schedules: Schedule[] = getSchedules();

export const rulesModels = {
  currently: {
    title: `⏰ Alerte Fermeture du pont chaban`,
    delayMinBefore: 60,
    scheduleIds: schedules.map(s => s.id),
    active: true,
    isEmailEnabled: false,
  },
  daily: {
    title: `🌉 Demain Fermeture du pont chaban`,
    delayMinBefore: 300,
    scheduleIds: schedules.filter(s => s.hour === 20).map(s => s.id),
    active: true,
    isEmailEnabled: false,
  },
  weekly: {
    title: `📅 Récap Hebdos du pont chaban`,
    delayMinBefore: 300,
    scheduleIds: schedules.filter(s => s.day === 0 && s.hour === 19).map(s => s.id),
    active: true,
    isEmailEnabled: false,
  },
};
